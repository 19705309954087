import React from "react"
import { Helmet } from "react-helmet"

export const SEO = ({ title }) => {
  const seo = {
    title:
      title ||
      "Hyfen8 - Intelligence that reveals the hidden value in any business",
  }

  return (
    <div>
      <Helmet>
        <title>{seo.title}</title>
        <meta
          name="description"
          content="Hyfen8 - Intelligence that reveals the hidden value in any business"
        />
        <meta
          name="og:title"
          property="og:title"
          content="Hyfen8 - Intelligence that reveals the hidden value in any business"
        ></meta>
        <meta
          name="twitter:card"
          content="Hyfen8 - Intelligence that reveals the hidden value in any business"
        ></meta>
        <meta property="og:image" content="/og-image.png" />
      </Helmet>
    </div>
  )
}

export default SEO
